// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agreement-js": () => import("./../src/pages/agreement.js" /* webpackChunkName: "component---src-pages-agreement-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-files-js": () => import("./../src/pages/my-files.js" /* webpackChunkName: "component---src-pages-my-files-js" */),
  "component---src-pages-operation-js": () => import("./../src/pages/operation.js" /* webpackChunkName: "component---src-pages-operation-js" */),
  "component---src-pages-policy-js": () => import("./../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-recruit-js": () => import("./../src/pages/recruit.js" /* webpackChunkName: "component---src-pages-recruit-js" */),
  "component---src-pages-service-level-agreements-js": () => import("./../src/pages/service-level-agreements.js" /* webpackChunkName: "component---src-pages-service-level-agreements-js" */)
}

